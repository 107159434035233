/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  // padding: 0.5rem 0;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
}
body {
  position: inherit;
  min-height: 100vh;
  color: var(--color-text);
  background: #eff1f6;
  transition: 0.2s color, background ease-in-out;
  line-height: 1.6;
  --ms-font-size: 1.6rem;
  --ms-line-height: 1.6;
  --ms-bg: #ffffff;
  --ms-border-color: var(--color-border);
  --ms-radius: 0.5rem;
  --ms-placeholder-color: var(--color-text-soft);
  --ms-dropdown-bg: #ffffff;
  --ms-ring-width: 0px;
  --ms-ring-color: var(--color-primary);
  --ms-py: 0.1rem;
  --ms-px: 1rem;
  --ms-max-height: 50rem;
  --ms-border-color: var(--color-border-soft);
  --ms-dropdown-radius: 1rem;
  --ms-dropdown-border-color: var(--color-border);
  --ms-group-label-bg: #ffffff;
  --ms-group-label-color: var(--color-text);
  --ms-group-label-bg-pointed: #ffffff;
  --ms-group-label-color-pointed: var(--color-text);
  --ms-group-label-line-height: 1.6;
  --ms-group-label-py: 0.5rem;
  --ms-group-label-px: 1.5rem;
  --ms-group-label-bg-selected: var(--color-primary);
  --ms-group-label-bg-selected-pointed: var(--color-primary);
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.6;
  --ms-option-bg-pointed: var(--color-secondary);
  --ms-option-color-pointed: var(--color-background);
  --ms-option-bg-selected: var(--color-primary);
  --ms-option-color-selected: var(--color-background);
  --ms-option-bg-selected-pointed: var(--color-primary);
  --ms-option-color-selected-pointed: var(--color-background);
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-selected-disabled: #d1fae5;
  --ms-option-py: 0.5rem;
  --ms-option-px: 1rem;
  --ms-tag-color: var(--color-background);
  --ms-tag-bg: var(--color-primary);
  --ms-tag-font-weight: 500;
  --ms-tag-py: 0.75rem;
  --ms-tag-px: 0.75rem;
  --ms-tag-remove-mx: 0.5rem;
  --ms-tag-radius: 1rem;

  --color-background: var(--c-white);
  --color-background-soft: var(--c-white-soft);
  --color-background-softer: var(--c-white-softer);
  --color-border: var(--c-grey-dark);
  --color-border-soft: var(--c-grey);
  --color-heading: var(--c-black-soft);
  --color-heading-alt: var(--c-primary);
  --color-text: var(--c-black);
  --color-text-soft: var(--c-black-soft);
  --color-highlight: var(--c-secondary);
  --color-primary: var(--c-primary);
  --color-secondary: var(--c-secondary);
  --color-primary-alt: var(--c-primary-alt);
  --color-secondary-alt: var(--c-secondary-alt);
  --color-gradient-text: var(--c-white);

  --c-white: #ffffff;
  --c-white-soft: #f2f4f7;
  --c-white-softer: #fdfdfd;
  --c-grey: #e8e8e8;
  --c-grey-dark: #aaadbc;
  --c-black: #000000;
  --c-black-soft: #31373b;
  --c-pink: #fd838d;
  --c-red: #d91b1b;
  --c-blue: #2049d6;
  --c-green: #4dc480;
  --c-cyan: #34acae;
  --c-orange: #ed7d31;
  --c-purple: #7222a1;
  --c-primary: #2049d6;
  --c-secondary: #fd838d;
  --c-primary-alt: #5933d2;
  --c-secondary-alt: #612157;

  --c-primary: rgb(142, 36, 170);
  --c-primary-alt: rgb(205, 18, 138);
}

.btn-outline-primary {
  --bs-btn-color: #3140a0;
  --bs-btn-border-color: #3141a0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #8627a9;
  --bs-btn-hover-border-color: #8627a9;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  font-weight: 600;
  line-height: 1.2;
}

.card-header {
  background-color: rgb(145 36 169 / 9%);
}

/* For WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width as per your preference */
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Change to desired background color */
}

::-webkit-scrollbar-thumb {
  background-color: #8e24aa; /* Change to desired thumb color */
  border-radius: 5px; /* Adjust the border radius as per your preference */
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #8e24aa;
  border-radius: 5px;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}

input::placeholder {
  color: #6b7478; /* replace with desired color */
  font-size: 14px;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Safari */
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: none; /* Chrome, Edge, Opera, and other Chromium-based browsers */
  -moz-appearance: textfield; /* Firefox */
  appearance: none;
}

.ngx-pagination {
  padding-left: 0;
  .current {
    background: linear-gradient(
      320deg,
      rgba(145, 36, 169, 1) 0%,
      rgba(191, 22, 145, 1) 100%
    ) !important;
    color: #fff;
  }
}

input[type="text"]:disabled {
  background-color: #fff;
  color: #888;
}

.table > :not(caption) > * > * {
  background-color: transparent !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(0.375em + 1.5rem) center;
}

.form-control:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #28c953;
      opacity: 0.6;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #28c953;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(2px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
    &:disabled {
      opacity: 0;
    }
    & + .slider {
      opacity: 1 !important;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8a8a8e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 3px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}

.ng-autocomplete {
  width: 100%;
  padding: 5px !important;
  .autocomplete-container {
    box-shadow: none !important;
    .input-container {
      input {
        width: 100%;
        background-color: transparent;
        color: #000 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:disabled {
          background-color: transparent !important;
          cursor: not-allowed;
        }
      }
      .x {
        right: 25px;
        i {
          color: #8e24aa;
        }
      }
    }
    .suggestions-container {
      margin-top: 5px;
      ul {
        li {
          border-bottom: 1px solid #e5e5e5;
          a {
            padding: 10px 15px;
          }
        }
      }
    }
  }
}

.usecase-form-group {
  .ng-autocomplete {
    .autocomplete-container {
      .input-container {
        input {
          padding: 0 25px 0 10px;
        }
      }
    }
  }
}

.ng-select {
  padding: 5px !important;
  .ng-select-container {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  &.ng-select-opened > .ng-select-container {
    background: transparent;
  }
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        padding-left: 10px;
        padding-top: 6px;
        .ng-input {
          padding: 0;
        }
        .ng-placeholder {
          top: 10px;
          padding-bottom: 0;
          padding-left: 5px;
        }
        .ng-value {
          font-size: 0.9em;
          margin-bottom: 5px;
          color: #fff;
          background-color: #8e24aa;
          border-radius: 2px;
          margin-right: 5px;
          .ng-value-icon {
            &.left {
              border-right: 1px solid #741c8d;
            }
          }
        }
      }
    }
  }
  .ng-arrow-wrapper,
  .ng-clear-wrapper {
    display: none;
  }

  &.ng-select-focused:not(.ng-select-opened) > {
    .ng-select-container {
      box-shadow: none;
      border: 0;
    }
  }
}
.autocomplete-overlay {
  position: absolute !important;
}

.spinner-outer {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto !important;
  width: 100%;
  height: 100%;
  z-index: 5;

  .loading-spinner {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 8px solid rgba(230, 230, 230, 1);
    border-radius: 50%;
    border-top-color: #9b2fa6;
    animation: spin 1s ease-in-out infinite;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    position: fixed;
  }
  &::before {
    background: rgba(230, 230, 230, 0.3);
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
  }
}

.login-form {
  .form-group {
    .password-icon {
      .form-control {
        &.is-invalid {
          background-image: none !important;
        }
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.heat-map-modal {
  .modal-dialog {
    min-width: calc(100% - 272px);
    margin: 2rem 8.5rem;
  }
  .modal-content {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border: unset;
  }
}
.make-it-case {
  div.modal-content {
    border-radius: 15px !important;
    height: 450px;
  }
}
.add-widget-modal {
  --bs-modal-width: 1100px;
  max-width: 100%;
  overflow-y: hidden;
  .modal-dialog {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    max-width: 800px;
  }
}
.owl-carousel{
  &.owl-theme{

position: relative;
  .owl-stage-outer{
width: 100%;
  }
  .owl-nav{
    position: absolute;
    top: 36px;
    width: 100%;
    .owl-prev{
      position: absolute;
      left: -13px;
      width: 18px;
      padding: 4px 0;
      margin-top: -15px;
      &.disabled{
      opacity: 0;
      }
    }
    .owl-next{
      position: absolute;
      right: -15px;
      width: 18px;
      padding: 4px 0;
      margin-top: -15px;
      &.disabled{
        opacity: 0;
        }
    }
  }
} 
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.heat-map-modal {
  .modal-dialog {
    min-width: calc(100% - 272px);
    margin: 2rem 8.5rem;
  }
  .modal-content {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border: unset;
  }
}
